import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-semibold text-gray-800">Terms of Service</h1>
      <p className="text-lg text-gray-600 mt-4">
        These Terms of Service govern your use of this website and any associated services. By accessing or using our website, you agree to comply with these terms.
      </p>

      {/* Section 1: Acceptance of Terms */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">1. Acceptance of Terms</h2>
        <p className="text-lg text-gray-600">
          By using this website, you agree to abide by these Terms of Service. If you do not agree with any part of these terms, you may not access or use the website.
        </p>
      </section>

      {/* Section 2: Changes to Terms */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">2. Changes to Terms</h2>
        <p className="text-lg text-gray-600">
          We reserve the right to modify or replace these terms at any time. Any changes will be posted on this page, and it is your responsibility to review these terms periodically.
          Your continued use of the website after any changes indicates your acceptance of the new terms.
        </p>
      </section>

      {/* Section 3: User Responsibilities */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">3. User Responsibilities</h2>
        <p className="text-lg text-gray-600">
          When using this website, you agree to:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 mt-4">
          <li>Use the website only for lawful purposes.</li>
          <li>Not engage in any activity that could harm or disrupt the website or other users.</li>
          <li>Provide accurate information when required (e.g., during registration).</li>
        </ul>
      </section>

      {/* Section 4: Intellectual Property */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">4. Intellectual Property</h2>
        <p className="text-lg text-gray-600">
          All content on this website, including but not limited to text, images, and code, is the intellectual property of CSE450.com or its content providers.
          You may not reproduce, distribute, or create derivative works from any content without prior written consent.
        </p>
      </section>

      {/* Section 5: Termination */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">5. Termination</h2>
        <p className="text-lg text-gray-600">
          We reserve the right to terminate or suspend access to our website at our sole discretion, without prior notice or liability, for any reason, including if you breach these terms.
        </p>
      </section>

      {/* Section 6: Governing Law */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">6. Governing Law</h2>
        <p className="text-lg text-gray-600">
          These Terms of Service are governed by and construed in accordance with the laws of United Kingdom, without regard to its conflict of law principles.
        </p>
      </section>

      {/* Section 7: Contact Information */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Contact Us</h2>
        <p className="text-lg text-gray-600">
          If you have any questions or concerns regarding these Terms of Service, please contact us at contact@cse450.com
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;