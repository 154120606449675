import React from 'react';

const Module3 = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-semibold text-gray-800">Module 3: Amortized Analysis</h1>
      <p className="text-lg text-gray-600 mt-4">
        Delve into the cost analysis of repeated operations in Amortized Analysis.
      </p>
    </div>
  );
};

export default Module3;