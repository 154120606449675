import React from 'react';
import { Link } from 'react-router-dom';
import MidtermOneNotes from '../assets/Midterm1Notes.pdf';

const Homepage = () => {
  return (
    <div>
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-extrabold mb-4">Welcome to CSE450</h2>
          <p className="text-xl mb-6">An all inclusive site for knowledge relating to Algorithm Design & Analysis</p>
          <a href="#modules" className="bg-white text-indigo-600 px-6 py-3 rounded-md shadow-md hover:bg-gray-200">
            Explore Modules
          </a>
        </div>
      </header>

      {/* Modules Section */}
      <section id="modules" className="container mx-auto px-4 py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {/* Notes */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Notes</h3>
          <p className="text-gray-600 mb-4">Other notes relating to the class</p>
            <a 
                href={MidtermOneNotes} 
                download="Midterm1Notes.pdf" 
                className="text-indigo-600 hover:underline"
            >
                Midterm 1 Notes
            </a><br />
        </div>

        {/* Module 0 */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Module 0: Prerequisites</h3>
          <p className="text-gray-600 mb-4">Prerequisite knowledge from CSE310 covering essential DS&A.</p>
          <Link to="/module0#subheading1" className="text-indigo-600 hover:underline">Subheading1</Link><br />
          <Link to="/module0#subheading2" className="text-indigo-600 hover:underline">Subheading2</Link><br />
          <Link to="/module0#subheading3" className="text-indigo-600 hover:underline">Subheading3</Link><br />
          <Link to="/module0#subheading4" className="text-indigo-600 hover:underline">Subheading4</Link>
        </div>

        {/* Module 1 */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Module 1: Stable Matching</h3>
          <p className="text-gray-600 mb-4">Explore the Stable Marriage Problem and other related topics</p>
          <Link to="/module1#StableMatchingProblem" className="text-indigo-600 hover:underline">Stable Matching Problem Outline</Link><br />
          <Link to="/module1#proposeandreject" className="text-indigo-600 hover:underline">Propose-And-Reject Algorithm</Link><br />
          <Link to="/module1#algoproperties" className="text-indigo-600 hover:underline">Algorithm Properties</Link><br />
          <Link to="/module1#fiverepresentative" className="text-indigo-600 hover:underline">Five Representative Problems</Link>
        </div>

        {/* Module 2 */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Module 2: Greedy Algorithms</h3>
          <p className="text-gray-600 mb-4">Understand Scheduling, Partitioning, and Minimizing Lateness.</p>
          <Link to="/module2#subheading1" className="text-indigo-600 hover:underline">Subheading1</Link><br />
          <Link to="/module2#subheading2" className="text-indigo-600 hover:underline">Subheading2</Link><br />
          <Link to="/module2#subheading3" className="text-indigo-600 hover:underline">Subheading3</Link><br />
          <Link to="/module2#subheading4" className="text-indigo-600 hover:underline">Subheading4</Link>
        </div>

        {/* Module 3 */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Module 3: Amortized Analysis</h3>
          <p className="text-gray-600 mb-4">Delve into cost analysis of repeated operations and data structures.</p>
          <Link to="/module3#subheading1" className="text-indigo-600 hover:underline">Subheading1</Link><br />
          <Link to="/module3#subheading2" className="text-indigo-600 hover:underline">Subheading2</Link><br />
          <Link to="/module3#subheading3" className="text-indigo-600 hover:underline">Subheading3</Link><br />
          <Link to="/module3#subheading4" className="text-indigo-600 hover:underline">Subheading4</Link>
        </div>

        {/* Module 4 */}
        <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-shadow">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Module 4: Splay Trees</h3>
          <p className="text-gray-600 mb-4">Study self-adjusting binary search trees and rotation complexities.</p>
          <Link to="/module4#subheading1" className="text-indigo-600 hover:underline">Subheading1</Link><br />
          <Link to="/module4#subheading2" className="text-indigo-600 hover:underline">Subheading2</Link><br />
          <Link to="/module4#subheading3" className="text-indigo-600 hover:underline">Subheading3</Link><br />
          <Link to="/module4#subheading4" className="text-indigo-600 hover:underline">Subheading4</Link>
        </div>
      </section>
    </div>
  );
};

export default Homepage;