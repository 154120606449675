import React from 'react';
// import testDiagram from '../assets/diagrams/test_diagram.png';

const Module1 = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-semibold text-gray-800 mb-8">Module 1: Stable Matching</h1>

      {/* Subheading 1: Overview */}
      <section id="StableMatchingProblem" className="mb-12">
        <h2 className="text-3xl font-semibold text-indigo-600 mb-4">Stable Matching Problem Outline</h2>
        <p className="text-lg text-gray-600">
          The motivation behind the following algorithms arise from the "Stable Marriage/Matching Problem". The way this problem is defined in this course is as follows:
        </p>
        <p className="text-lg text-gray-600 mt-4">
          Given n Ph.D. advisors and n students, find a "suitable" matching.
          <ul className="list-disc list-inside text-lg text-gray-600">
          <li>Each Ph.D. advisor lists students in order of preference from best to worst</li>
          <li>Each student lists advisors in order of preference from best to worst</li>
          <li>Assume, only one student is assigned to every Ph.D. advisor</li>
        </ul>
        </p>
        <p className="text-lg text-gray-600 mt-4">
          Let us also define some termniology:
          <ul className="list-disc list-inside text-lg text-gray-600">
          <li><strong>Perfect matching:</strong> Each student gets exactly one advisor, and each advisor gets exactly one student</li>
          <li><strong>Stability:</strong> No pair of student, advisor would prefer each other over their current assignment</li>
          <li><strong>Stable matching:</strong> Perfect matching with no unstable pairs</li>
        </ul>
        </p>
      </section>

      {/* Subheading 2: Blah */}
      <section id="proposeandreject" className="mb-12">
        <h2 className="text-3xl font-semibold text-indigo-600 mb-4">Propose-And-Reject Algorithm</h2>

        <p className="text-lg text-gray-600">
          An intuitve solution to this problem was proposed in 1962, called the "Propose-And-Reject" algorithm
        </p>
        <pre className="bg-gray-100 text-gray-800 p-4 rounded-md overflow-x-auto">

{`Initialize each person to be free.
while (some Ph.D. advisor is free and hasn't proposed to every
student) {
  Choose such a Ph.D. advisor p
  s = 1st student on p's list to whom p has not yet proposed
  if (s is free)
    assign p and s to be matched
  else if (s prefers p to her current advisor p')
    assign s to p, and p' to be free
  else
    s rejects p
}
`}
        </pre>
        <p className="text-sm text-gray-500 text-center mt-2">Propose-and-Reject Algorithm - Gale-Shapley</p>
        <p className="text-lg text-gray-600">
          The idea behind this algorithm is that Ph.D. advisors propose to whoever is highest on their preference list. If the student is free they will automatically accept. Otherwise the student will only accept if they prefer the proposer to the current advisor.
        </p>
        <br></br>
        <p className="text-lg text-gray-600">
          Proof of correctness is fairly simple.
        </p>
        <p className="text-lg text-gray-600">
        We can observe that Ph.D. advisors propose in decreasing order of preference and that students only "trade up" once matched.
        </p>
        <p className="text-lg text-gray-600">
        It is also trivial to prove all advisors and students get matched.
        </p>
        <br></br>
        <p className="text-lg text-gray-600">
        Now to prove stability, we assume a contradiction, i.e. there exists a pair that prefer each other. <br></br>
        There are two cases that follow <br></br>
        (a) The advisor did not propose to the student, in which case he prefers someone else because of decreasing order of preference
        <br></br>
        (b) The advisor proposed and student rejected, in which case students only trade up
        </p>
      </section>

      {/* Subheading 3: Blah */}
      <section id="algoproperties" className="mb-12">
        <h2 className="text-3xl font-semibold text-indigo-600 mb-4">Algorithm Properties</h2>
        <p className="text-lg text-gray-600">
          This algorithm runs in O(n<sup>2</sup>) time
        </p>
        <p className="text-lg text-gray-600">
          This algorithm is advisor optimal; the advisor is always paired with their best match if multiple matches exist - You should read proof!
        </p>
        <p className="text-lg text-gray-600">
          This algorithm is student pessimal; the student is always paired with their worst match if multiple matches exist - You should read proof!
        </p>
      </section>

      {/* Subheading 4: Blah */}
      <section id="fiverepresentative" className="mb-12">
        <h2 className="text-3xl font-semibold text-indigo-600 mb-4">Five Representative Problems</h2>
        <p className="text-lg text-gray-600">
          The "Five Representative Problems" which we cover briefly in this module are
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 mt-4">
          <li>Interval Scheduling</li>
          <li>Weighted Interval Scheduling</li>
          <li>Bipartite Matching</li>
          <li>Independent Set</li>
          <li>Competitive Facility Location</li>
        </ul>
      </section>
    </div>
  );
};

export default Module1;