import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-semibold text-gray-800">Privacy Policy</h1>
      <p className="text-lg text-gray-600 mt-4">
        Your privacy is important to us. This Privacy Policy outlines the types of personal information that we receive and
        collect when you use our website, and how we safeguard your information. By using our website, you consent to the
        practices described in this policy.
      </p>

      {/* Section 1: Information Collection */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">1. Information We Collect</h2>
        <p className="text-lg text-gray-600">
          We may collect the following types of information:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 mt-4">
          <li>Personal Information: such as your name, email address, and other contact details that you voluntarily provide.</li>
          <li>Usage Data: such as your IP address, browser type, operating system, and pages visited on the site.</li>
          <li>Cookies: used to enhance your experience on the site, track user behavior, and remember preferences.</li>
        </ul>
      </section>

      {/* Section 2: How We Use Your Information */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">2. How We Use Your Information</h2>
        <p className="text-lg text-gray-600">
          The information we collect is used for the following purposes:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-600 mt-4">
          <li>To provide, operate, and maintain our website.</li>
          <li>To improve and personalize your experience on the site.</li>
          <li>To understand and analyze how you use our website.</li>
          <li>To communicate with you, respond to inquiries, and provide support.</li>
        </ul>
      </section>

      {/* Section 3: Data Protection */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">3. Data Protection</h2>
        <p className="text-lg text-gray-600">
          We are committed to protecting your data. We implement a variety of security measures to maintain the safety of your
          personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we
          cannot guarantee its absolute security.
        </p>
      </section>

      {/* Section 4: Changes to this Policy */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">4. Changes to This Privacy Policy</h2>
        <p className="text-lg text-gray-600">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to
          review this policy periodically. Your continued use of the website after any changes signifies your acceptance of the
          new terms.
        </p>
      </section>

      {/* Contact Information */}
      <section className="mt-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Contact Us</h2>
        <p className="text-lg text-gray-600">
          If you have any questions about this Privacy Policy, please contact us at contact@cse450.com
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;