import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current); // Clear the timeout to prevent closing when hovering
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    // Set a timeout to delay the closing of the dropdown
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 200); // 200ms delay
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo clickable to go home */}
        <Link to="/" className="text-2xl font-bold text-gray-800 hover:text-indigo-600">
          CSE450
        </Link>

        {/* Dropdown container */}
        <div
          className="relative inline-block"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Button */}
          <button className="text-gray-700 px-3 py-2 rounded-md hover:bg-gray-200 focus:outline-none">
            Modules
          </button>

          {/* Dropdown */}
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white shadow-md flex flex-col z-10">
              <div className="py-2">
                <Link to="/module0" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  Module 0: Prerequisites
                  <p className="text-xs text-gray-500">Prerequisite Knowledge from CSE310</p>
                </Link>
                <Link to="/module1" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  Module 1: Stable Matching
                  <p className="text-xs text-gray-500">Exploring Stable Marriage Problem Algorithm</p>
                </Link>
                <Link to="/module2" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  Module 2: Greedy Algorithms
                  <p className="text-xs text-gray-500">Scheduling, Partitioning, and Lateness</p>
                </Link>
                <Link to="/module3" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  Module 3: Amortized Analysis
                  <p className="text-xs text-gray-500">Cost Analysis of Repeated Operations</p>
                </Link>
                <Link to="/module4" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  Module 4: Splay Trees
                  <p className="text-xs text-gray-500">Self-adjusting Binary Search Trees</p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
