import React from 'react';

const Module4 = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-semibold text-gray-800">Module 4: Splay Trees</h1>
      <p className="text-lg text-gray-600 mt-4">
        Study self-adjusting binary search trees and rotation complexities in Splay Trees.
      </p>
    </div>
  );
};

export default Module4;