import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8 border-t border-gray-700 shadow-md">
      <div className="container mx-auto px-4 text-center">
        <p className="text-xl font-semibold mb-2">&copy; 2024 CSE450 - Algorithm Design & Analysis</p>
        <p className="text-sm text-gray-400">All rights reserved.</p>
        <div className="flex justify-center space-x-8 mt-4">
          <Link to="/privacy-policy" className="text-gray-400 hover:text-white transition-colors duration-200">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="text-gray-400 hover:text-white transition-colors duration-200">
            Terms of Service
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
