import React from 'react';

const Module0 = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-semibold text-gray-800">Module 0: Introduction</h1>
      <p className="text-lg text-gray-600 mt-4">
        Introduction to Algorithms & Design.
      </p>
    </div>
  );
};

export default Module0;